import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import TermsAndConditionsContent from "../components/TermsAndConditionsContent";

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const { siteUrl } = site.siteMetadata;

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <Layout pageDescription="Read the legal Terms of Service that govern how we work">
      <TermsAndConditionsContent siteUrl={siteUrl} />
    </Layout>
  );
};
